import { ANIMATION_MODULE_TYPE, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgDocTooltipDirective } from '@ng-doc/ui-kit';
import { NgDocRootComponent, NgDocNavbarComponent, NgDocSidebarComponent, NgDocThemeService } from '@ng-doc/app';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastStateService } from './core/services';
import { DOCS_APP_LIGHT_THEME, GitHubIcon, LightbulbIcon, REPOSITORY_URL } from './core';
import { VmrToastContainer } from '@vermeer-corp/it-ng-components/toast';
import { VmrLogo, VmrLogoVertical } from '@vermeer-corp/it-ng-components/logo';
import { NG_DOC_NIGHT_THEME } from '@ng-doc/app/constants';
import { VmrThemeManager, VmrThemeToggle } from '@vermeer-corp/it-ng-components/theme-manager';
import { explicitEffect } from '@vermeer-corp/it-ng-components/core';
import {
  faStar,
  faTimes,
  faSearch,
  faCheck,
  faMinus,
  faComment,
  faInfoCircle,
  faXmarkCircle,
  faCheckCircle,
  faExternalLinkAlt,
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'app-root',
  styleUrl: 'app.component.scss',
  templateUrl: 'app.component.html',
  imports: [
    VmrLogo,
    GitHubIcon,
    RouterOutlet,
    LightbulbIcon,
    VmrThemeToggle,
    VmrLogoVertical,
    VmrToastContainer,
    FontAwesomeModule,
    NgDocRootComponent,
    NgDocNavbarComponent,
    NgDocSidebarComponent,
    NgDocTooltipDirective
  ]
})
export class AppComponent {
  private readonly _ngDocTheme = inject(NgDocThemeService);
  private readonly _themeManager = inject(VmrThemeManager);
  private readonly _faLibrary = inject(FaIconLibrary);
  private readonly _toastState = inject(ToastStateService);
  protected _animationsDisabled = inject(ANIMATION_MODULE_TYPE, {optional: true}) === 'NoopAnimations';

  readonly _repoUrl = REPOSITORY_URL;
  readonly _issuesUrl = `${REPOSITORY_URL}/issues`;
  readonly _year = new Date().getFullYear();

  position = this._toastState.position.asReadonly();
  expand = this._toastState.expandByDefault.asReadonly();
  closeButton = this._toastState.showCloseButton.asReadonly();
  renderToastContainer = this._toastState.renderToastContainer.asReadonly();

  isDarkMode = this._themeManager.isDarkMode;

  /**
   * Ensure concrete instance of `FaIconLibrary` method calls in non-test environments.
   * `FontAwesomeTestingModule` referenced in testing modules/components already does this,
   * however, errors will still be thrown by fontawesome as a double-check.
   *
   * The effect is needed to keep our theme synchronized with ng-doc's theme.
   */
  constructor() {
    if (!this._animationsDisabled) {
      this._addFaIcons();

      explicitEffect(
        [this.isDarkMode],
        ([isDarkMode]) => {
          const currentTheme = isDarkMode ? NG_DOC_NIGHT_THEME.id : DOCS_APP_LIGHT_THEME;
          this._ngDocTheme.set(currentTheme, false);
        },
        { defer: true }
      );
    }
  }

  private _addFaIcons(): void {
    this._faLibrary.addIcons(
      faStar,
      faTimes,
      faCheck,
      faMinus,
      faSearch,
      faComment,
      faInfoCircle,
      faCheckCircle,
      faXmarkCircle,
      faExternalLinkAlt,
      faExclamationCircle,
      faExclamationTriangle
    );
  }
}
